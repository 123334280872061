const benefit = [
  {
    benefit: "Tiket PP dari Surabaya dan Jakarta",
  },
  {
    benefit: "Hotel Berbintang Setaraf",
  },
  {
    benefit: "Tour Leader & Pembimbing Ibadah",
  },
  {
    benefit: "Sertifikat Umroh Hanan",
  },
  {
    benefit: "Air Zam-Zam 5 Liter",
  },
  {
    benefit: "Makan 3x Sehari",
  },
  {
    benefit: "City Tour",
  },
  {
    benefit: "VISA",
  },
  {
    benefit: "Dan Lain-Lain...",
  },
];

const csNumbers = [
  "6282338319023",
  "6282338223542",
  "6282338223537",
  "6282337817698",
];

const banner = [
  {
    id: "promo-umroh-beli-1-gratis-1",
    banner: "hero-1+1.png",
    day: "u-9d.png",
    condition: "",
    bg: "section-2-promo",
    bg_box: "box-fasilitas-promo",
    message:
      "text=Assalamualaikum+Admin+Nusantara+Umroh%2C+Saya+ingin+bertanya+tentang+Promo+Nusantara+Umroh+Beli+1+Gratis+1&type=phone_number&app_absent=0",
  },
  {
    id: "promo-umroh-beli-2-gratis-1",
    banner: "hero-2+1.png",
    day: "u-12d.png",
    condition: "",
    bg: "section-2-promo",
    bg_box: "box-fasilitas-promo",
    message:
      "text=Assalamualaikum+Admin+Nusantara+Umroh%2C+Saya+ingin+bertanya+tentang+Promo+Nusantara+Umroh+Beli+2+Gratis+1&type=phone_number&app_absent=0",
  },
  {
    id: "promo-umroh-12-hari",
    banner: "hero-12d.png",
    day: "u-12d.png",
    condition: "",
    bg: "section-2-promo",
    bg_box: "box-fasilitas-promo",
    message:
      "text=Assalamualaikum+Admin+Nusantara+Umroh%2C+Saya+ingin+bertanya+tentang+Promo+Nusantara+Umroh+12+Hari+Hanya+22,5jt&type=phone_number&app_absent=0",
  },
  {
    id: "promo-umroh-9-hari",
    banner: "hero-9d.png",
    day: "u-9d.png",
    condition: "",
    bg: "section-2-promo",
    bg_box: "box-fasilitas-promo",
    message:
      "text=Assalamualaikum+Admin+Nusantara+Umroh%2C+Saya+ingin+bertanya+tentang+Promo+Nusantara+Umroh+9+Hari+Hanya+19,5jt&type=phone_number&app_absent=0",
  },
  {
    id: "promo-umroh-hotel-bintang-4-12-hari",
    banner: "hero-12d-1.png",
    day: "u-9d.png",
    condition: "",
    bg: "section-2-promo",
    bg_box: "box-fasilitas-promo",
    message:
      "text=Assalamualaikum+Admin+Nusantara+Umroh%2C+Saya+ingin+bertanya+tentang+Promo+Nusantara+Umroh+12+Hari+Dengan+Hotel+Bintang+4&type=phone_number&app_absent=0",
  },
  {
    id: "program-talangan-hanan-nusantara",
    banner: "hero-talangan.png",
    day: "talangan-icon.png",
    condition: "talangan",
    bg: "section-2",
    bg_box: "box-fasilitas",
    message:
      "text=Assalamualaikum+Admin+Hanan+Nusantara%2C+Saya+ingin+bertanya+tentang+Program+Talangan&type=phone_number&app_absent=0",
  },
];

const csNumber = "6281918065183";

export { benefit, csNumber, csNumbers, banner };
