import React, { useEffect } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { banner, benefit, csNumbers } from "../components/utils";
import { getLink } from "../helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

const Promo = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const promoParam = params.get("t");
  console.log(promoParam);

  useEffect(() => {
    getBannerById(promoParam);
  }, [promoParam]);

  const getBannerById = (id) => {
    const res = banner.find((item) => item.id === id);
    if (res !== undefined) {
      return res;
    } else {
      return {
        id: "promo-umroh-beli-1-gratis-1",
        banner: "hero-1+1.png",
        day: "u-9d.png",
        condition: "",
        bg: "section-2-promo",
        bg_box: "box-fasilitas-promo",
        message:
          "text=Assalamualaikum+Admin+Nusantara+Umroh%2C+Saya+ingin+bertanya+tentang+Promo+Nusantara+Umroh+Beli+1+Gratis+1&type=phone_number&app_absent=0",
      };
    }
  };

  return (
    <Container className="promo" fluid>
      <Col className="hot-promo">
        <FontAwesomeIcon icon={faFire} color="white" size="xl" />
        <h4
          style={{
            color: "white",
            marginBottom: "-2px",
            marginLeft: "10px",
            fontWeight: "800",
          }}
        >
          HANYA SEKARANG
        </h4>
      </Col>
      <Button
        className="btn-goto-wa"
        onClick={() =>
          getLink(
            `https://api.whatsapp.com/send/?phone=${
              csNumbers[Math.floor(Math.random() * csNumbers.length)]
            }&${getBannerById(promoParam).message}`
          )
        }
      />
      <Col style={{ width: "100%", zIndex: "0" }}>
        <Image
          src={require(`../images/mobile/${getBannerById(promoParam).banner}`)}
          style={{ width: "100%" }}
        />
      </Col>
      <Col className={`${getBannerById(promoParam).bg} text-center`}>
        <Button
          className="btn-hero-wa-promo mb-4"
          onClick={() =>
            getLink(
              `https://api.whatsapp.com/send/?phone=${
                csNumbers[Math.floor(Math.random() * csNumbers.length)]
              }&${getBannerById(promoParam).message}`
            )
          }
        >
          <Image
            src={require("../images/mobile/button-text.png")}
            style={{ width: "80%" }}
          />
        </Button>
        <Col
          style={{ width: "100%", zIndex: "0" }}
          className="text-center mb-4"
        >
          <Image
            src={require(`../images/mobile/${getBannerById(promoParam).day}`)}
            style={{ width: "80%" }}
          />
        </Col>
        {getBannerById(promoParam).condition === "talangan" && (
          <Col>
            <Image
              src={require(`../images/mobile/program-cicilan.png`)}
              style={{ width: "80%" }}
              className="mb-3"
            />
            <Col className="d-flex justify-content-center mb-3">
              <Col xs={4}>
                <Image
                  src={require(`../images/mobile/tanpa-bunga.png`)}
                  style={{ width: "70%" }}
                />
              </Col>
              <Col xs={4}>
                <Image
                  src={require(`../images/mobile/tanpa-jaminan.png`)}
                  style={{ width: "70%" }}
                />
              </Col>
              <Col xs={4}>
                <Image
                  src={require(`../images/mobile/tanpa-riba.png`)}
                  style={{ width: "70%" }}
                />
              </Col>
            </Col>
            <Image
              src={require(`../images/mobile/meninggal-lunas.png`)}
              style={{ width: "70%" }}
              className="mb-4"
            />
            <Image
              src={require(`../images/mobile/table-talangan.png`)}
              style={{ width: "80%" }}
              className="mb-3"
            />
            <Image
              onClick={() =>
                getLink(
                  `https://api.whatsapp.com/send/?phone=${
                    csNumbers[Math.floor(Math.random() * csNumbers.length)]
                  }&${getBannerById(promoParam).message}`
                )
              }
              src={require(`../images/mobile/btn-donwload.png`)}
              style={{ width: "70%" }}
              className="mb-4 bt-donwload"
            />
          </Col>
        )}
        <Col>
          <Image
            src={require("../images/mobile/hotel-4.png")}
            style={{ width: "95%", marginBottom: "2rem" }}
          />
        </Col>
        <Col className={`${getBannerById(promoParam).bg_box} mb-3`}>
          <Image
            src={require("../images/mobile/fasilitas-umroh.png")}
            style={{ width: "100%", marginBottom: "20px" }}
          />
          {benefit.map((item, i) => {
            return (
              <Row key={i} className="text-white mb-2">
                <Col className="number-circle" xs={1}>
                  {i + 1}
                </Col>
                <Col>
                  <h5>{item.benefit}</h5>
                </Col>
              </Row>
            );
          })}
          <Button
            className="btn-hero-wa-box-promo mb-4"
            onClick={() =>
              getLink(
                `https://api.whatsapp.com/send/?phone=${
                  csNumbers[Math.floor(Math.random() * csNumbers.length)]
                }&${getBannerById(promoParam).message}`
              )
            }
          >
            <Image
              src={require("../images/mobile/button-text.png")}
              style={{ width: "90%" }}
            />
          </Button>
        </Col>
        <Col className="text-center">
          <Image
            src={require("../images/mobile/tx_legalitas.png")}
            style={{ width: "80%" }}
            className="mb-4"
          />

          <Image
            src={require("../images/mobile/legalitas.png")}
            style={{ width: "90%" }}
          />
        </Col>

        <Col className="section-3">
          <Image
            src={require("../images/mobile/galery-umroh.png")}
            style={{ width: "100%", marginBottom: "20px" }}
          />
          <Col>
            <Image
              src={require("../images/mobile/gal-1.png")}
              style={{ width: "100%", marginBottom: "10px" }}
            />
            <Image
              src={require("../images/mobile/gal-2.png")}
              style={{ width: "100%", marginBottom: "10px" }}
            />
            <Image
              src={require("../images/mobile/gal-3.png")}
              style={{ width: "100%", marginBottom: "10px" }}
            />
          </Col>
        </Col>
        <Image
          src={require("../images/mobile/testimoni.png")}
          style={{ width: "70%", marginBottom: "20px", marginTop: "4rem" }}
        />
        <Col className="border-yt mb-3">
          <iframe
            width="90%"
            src="https://www.youtube.com/embed/UR3DJ54lvj0"
            title="TANGIS HARU JAMA&#39;AH UMROH "
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
        </Col>
        <Col className="border-yt mb-3">
          <iframe
            width="90%"
            src="https://www.youtube.com/embed/jbtYk6Asvf4"
            title="JUANDA KEMBALI MEMBIRU!!! 250 jama&#39;ah Hanan Nusantara Kembali Ke Tanah Air"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
        </Col>
        <Col className="border-yt">
          <iframe
            width="90%"
            src="https://www.youtube.com/embed/HrHi5zUBqas"
            title="Maulid Nabi &amp; Silaturahmi Akbar | Pt Hanan Nusatara &amp; Tabung Haji Umroh | [ HIGHLIGHT ]"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </Col>
      </Col>
    </Container>
  );
};

export default Promo;
